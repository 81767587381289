<script lang="ts" setup>
import type { VoixWysiwygFieldInterface } from '@voix/components/fields/wysiwyg/types'
import { toTypedSchema } from '@vee-validate/zod'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as zod from 'zod'

defineProps<{
  fields: {
    description: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'LBM Contact Form', group: 'LBM Forms', layouts: ['LogicbombMedia'] },
  description: 'A contact form for users to get in touch with the site owner.',
  preview: 'SlicesLbmFormsContactForm.jpg',
  fields: {
    description: {
      type: 'wysiwyg',
      label: 'Description',
      default: '<p>We look forward to learning about your project. You can expect a response usually same day Monday - Friday Eastern Standard Time.</p>',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      validationSchema: {
        firstName: 'string',
        lastName: 'string',
        email: 'string',
        phoneNumber: 'string',
      },
    },
  }],
})

const phoneRegex = /^\+?(\d[\d\s-]{7,}\d)$/

const validationSchema = toTypedSchema(
  zod.object({
    firstName: zod.string().min(1, 'First name is required'),
    lastName: zod.string().min(1, 'Last name is required'),
    email: zod.string().min(1, 'Email is required').email({ message: 'Must be a valid email' }),
    phoneNumber: zod.string().min(9, 'Phone Number is required').regex(phoneRegex, { message: 'Must be a valid phone number' }),
  }),
)

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  message: '',
  complete: false,
})

async function submitContact() {
  await $fetch('/api/contact-form', {
    method: 'POST',
    body: JSON.stringify(form),
  }).then(() => {
    form.firstName = ''
    form.lastName = ''
    form.email = ''
    form.phoneNumber = ''
    form.message = ''
    form.complete = true
  }).catch(err => console.error(err))
}
</script>

<template>
  <div id="contact" class="relative isolate">
    <div class="grid grid-cols-1 lg:grid-cols-12">
      <div class="lg:col-span-5 relative lg:pb-20 pt-24 sm:pt-32 lg:static lg:py-48 px-12 lg:px-24 ">
        <div class="max-w-4xl">
          <h2 class="text-5xl font-lbm-serif font-normal pb-3 antialiased">
            Get in touch
          </h2>
          <div class="prose prose-invert prose-lg">
            <VoixWysiwyg class="font-lbm-serif text-stone-400" :field="fields.description" />
          </div>

          <dl class="mt-10 space-y-4 text-base leading-7 text-gray-300">
            <div class="flex gap-x-4">
              <dt class="flex-none">
                <span class="sr-only">Telephone</span>
                <Icon name="heroicons:phone-20-solid" class="h-7 w-6 text-gray-400" aria-hidden="true" />
              </dt>
              <dd class="font-sans">
                <a class="hover:text-white" href="tel:+12293038649">+1 (229) 303-8649</a>
              </dd>
            </div>
            <div class="flex gap-x-4">
              <dt class="flex-none">
                <span class="sr-only">Email</span>
                <Icon name="heroicons:envelope-20-solid" class="h-7 w-6 text-gray-400" aria-hidden="true" />
              </dt>
              <dd class="font-sans">
                <a class="hover:text-white" href="mailto:info@lbm.co">info@lbm.co</a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Form v-if="!form.complete" :validation-schema="validationSchema" class="lg:col-span-5 lg:col-start-6 font-sans pb-24 pt-20 sm:pb-32 lg:py-48" @submit="submitContact">
        <ClientOnly>
          <div class="lg:mx-auto px-12 lg:px-0 lg:mr-0 lg:max-w-lg">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <div class="flex justify-between items-end">
                  <label for="first-name" class="block text-sm font-light leading-6 text-white">First name*</label>
                  <div class="uppercase text-slate-500 text-[10px] font-sans font-thin">
                    Required
                  </div>
                </div>
                <div class="mt-2">
                  <Field id="first-name" v-model="form.firstName" type="text" name="firstName" autocomplete="given-name" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  <ErrorMessage class="pt-1 text-sm text-red-400" name="firstName" />
                </div>
              </div>
              <div>
                <div class="flex justify-between items-end">
                  <label for="last-name" class="block text-sm font-light leading-6 text-white">Last name*</label>
                  <div class="uppercase text-slate-500 text-[10px] font-sans font-thin">
                    Required
                  </div>
                </div>
                <div class="mt-2">
                  <Field id="last-name" v-model="form.lastName" type="text" name="lastName" autocomplete="family-name" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  <ErrorMessage class="pt-1 text-sm text-red-400" name="lastName" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <div class="flex justify-between items-end">
                  <label for="email" class="block text-sm font-light leading-6 text-white">Email*</label>
                  <div class="uppercase text-slate-500 text-[10px] font-sans font-thin">
                    Required
                  </div>
                </div>
                <div class="mt-2">
                  <Field id="email" v-model="form.email" type="email" name="email" autocomplete="email" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  <ErrorMessage class="pt-1 text-sm text-red-400" name="email" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <div class="flex justify-between items-end">
                  <label for="phone-number" class="block text-sm font-light leading-6 text-white">Phone number*</label>
                  <div class="uppercase text-slate-500 text-[10px] font-sans font-thin">
                    Required
                  </div>
                </div>
                <div class="mt-2">
                  <Field id="phone-number" v-model="form.phoneNumber" type="tel" name="phoneNumber" autocomplete="tel" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                  <ErrorMessage class="pt-1 text-sm text-red-400" name="phoneNumber" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="message" class="block text-sm font-light leading-6 text-white">Message (optional)</label>
                <div class="mt-2.5">
                  <textarea id="message" v-model="form.message" name="message" rows="4" class="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                </div>
              </div>
            </div>
            <div class="mt-8 flex justify-end">
              <button type="submit" class="bg-orange-600 text-white px-4 py-3 font-lbm-sans uppercase font-bold pointer-events-auto">
                Send Message
              </button>
            </div>
          </div>
        </ClientOnly>
      </Form>

      <div v-else class="col-span-6 font-lbm-serif max-w-2xl px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-56 text-center text-3xl">
        Form submitted successfully! We will be in touch as soon as possible!
      </div>
    </div>
  </div>
</template>
